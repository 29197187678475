<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Nombre de voyageur par pays à l'entrée des frontières terrestres
      </b-card-title>
    </b-card-header>

    <b-card-body>
      <div class="row">
        <div class="col-lg-12">
          <div v-append-export-button />

          <b-table
            responsive
            :items="getTableData"
            :fields="tableColumns"
            :busy="isLoadingTableData"
            show-empty
            empty-text="Aucune donnée trouvée"
            hover
          >
            <template #thead-top="data">
              <b-tr>
                <b-th />
                <b-th colspan="3" class="text-center">
                  Nombre de voyageurs à l'entrée des frontières terrestres
                </b-th>
              </b-tr>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <template #cell(provenance)="data">
              <div class="">
                <b-badge variant="light-success">
                  {{ data.item.provenance }}
                </b-badge>
              </div>
            </template>
            <template #cell(residence)="data">
              <div class="">
                <b-badge variant="light-danger">
                  {{ data.item.residence }}
                </b-badge>
              </div>
            </template>
            <template #cell(destination)="data">
              <div class="">
                <b-badge variant="light-success">
                  {{ data.item.destination }}
                </b-badge>
              </div>
            </template>
          </b-table>

          <PaginationComponent
            :table-data="getTableData"
            :per-page="paginationData.perPage"
            :total-rows="paginationData.total"
            @change="onPaginationChange"
          />
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BTable,
  BFormSelect,
  BFormInput,
  BSpinner,
  BBadge,
  BTh,
  BTr,
} from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import { mapActions, mapState } from "vuex";
import utilsService from "@/services/utils/utils.service";
import PaginationComponent from "@/components/PaginationComponent.vue";
// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import statisticsStoreModule from "@/store/statistics";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

const { countries: listCountries } = utilsService.countriesAndPlaces({
  stringOnly: false,
});
// console.log('listCountries :>> ', listCountries)
export default {
  components: {
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BTable,
    BFormSelect,
    BFormInput,
    BBadge,
    PaginationComponent,
    BTh,
    BTr,
  },
  props: {
    tableDatas: {
      type: Array,
      default: () =>
        listCountries.map((c) => ({
          ...c,
          provenance: 0,
          residence: 0,
          destination: 0,
        })),
    },
    isLoadingTableData: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "statistics", module: statisticsStoreModule },
    ];

    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      paginationData: {
        perPage: 10,
        total: 0,
      },
      listCountries,
      // isLoadingTableData: false,
      // tableDatas: {
      //   departements: [],
      //   communes: [],
      //   arrondissements: [],
      //   villages: [],
      // },
      tableColumns: [
        { key: "name", label: "Pays", sortable: true },
        {
          key: "provenance",
          label: "Provenance",
          sortable: true,
        },
        {
          key: "residence",
          label: "Résidence",
          sortable: true,
        },
        {
          key: "destination",
          label: "Destination",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    tableDatas: {
      deep: true,
      immediate: true,
      handler(val, old) {
        // this.filteredTableDatas[this.selectedTypeLocalisation] = val[this.selectedTypeLocalisation]
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  async mounted() {},
  methods: {
    ...mapActions("statistics", {
      action_getStatisticsAgencesVoyageToursOperators:
        "getStatisticsAgencesVoyageToursOperators",
    }),
    ...mapActions("etablissements", {
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    onPaginationChange(val) {
      // console.log('paginationChange', val)
      this.paginationData = { ...this.paginationData, ...val };
    },
    localFilter() {
      // const query = this.localSearchQuery.toLowerCase()
      // this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
      //   this.selectedTypeLocalisation
      // ].filter(e => {
      //   if (!query) return true
      //   return e.name.toLowerCase().includes(query)
      // })
      // this.filteredTableDatas[this.selectedTypeLocalisation] = this.filteredTableDatas[this.selectedTypeLocalisation].filter(
      //   e => true,
      // )
    },
    getRandomInt(min = 10, max = 1000) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    getTableData() {
      const items = [...this.tableDatas];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.paginationData.total = items.length || 0;
      const paginateArray = (array, perPage, page) =>
        array.slice((page - 1) * perPage, page * perPage);
      const { perPage, currentPage } = this.paginationData;
      // console.log(items, this.paginationData)
      return paginateArray(items, perPage, currentPage || 1);
      return this.filteredTableDatas[this.selectedTypeLocalisation];
    },
  },
};
</script>
